import * as React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const SecondPage = () => (
  <Layout>
    <Seo title="Contact" />
    <div className="contact-main">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mb-4 contact-left">
            <h3>Hãy gửi tin nhắn cho chúng tôi</h3>
            <form name="sentMessage" id="contactForm" action="https://getform.io/f/569b3125-88be-4ed5-9a4a-787ac29a0dd6" method="POST">
              <div className="control-group form-group">
                <div className="controls">
                  <input type="text" placeholder="Họ và tên" className="form-control" name="name" required data-validation-required-message="Please enter your name." />
                  <p className="help-block"></p>
                </div>
              </div>
              <div className="control-group form-group">
                <div className="controls">
                  <input type="tel" placeholder="Số điện thoại" className="form-control" name="phone" required data-validation-required-message="Please enter your phone number." />
                </div>
              </div>
              <div className="control-group form-group">
                <div className="controls">
                  <input type="email" placeholder="Địa chỉ Email" className="form-control" name="email" required data-validation-required-message="Please enter your email address." />
                </div>
              </div>
              <div className="control-group form-group">
                <div className="controls">
                  <textarea rows="5" cols="100" placeholder="Lời nhắn" className="form-control" name="message" required data-validation-required-message="Hãy nhập lời nhắn của bạn" maxLength="999" style={{resize: "none"}}></textarea>
                </div>
              </div>
              <div id="success"></div>

              <button type="submit" className="btn btn-primary" id="sendMessageButton">Gửi tin nhắn</button>
            </form>
          </div>

          <div className="col-lg-4 mb-4 contact-right">
            <h3>Thông tin liên hệ</h3>
            <p>
              Tầng 3, Toà nhà Newport, 667/77 Điện Biên Phủ, Phường 25, Quận Bình Thạnh,
              <br/> Thành phố Hồ Chí Minh
              <br/>
            </p>
            <p>
              <abbr title="Phone">P</abbr>: (84) 918202402
            </p>
            <p>
              <abbr title="Email">E</abbr>:
              <a href="mailto:sales@phuonglong.com"> sales@phuonglong.com </a>
            </p>
            <p>
              <abbr title="Hours">H</abbr>: Thứ 2 - Thứ 6: 9:00 AM to 5:00 PM
            </p>
          </div>
        </div>
      </div>
	  </div>
  </Layout>
)

export default SecondPage
